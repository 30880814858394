<template>
  <button @click="fbLogin" class="btn btn-facebook w-100 mb-2">
    <i class="fa fa-facebook" aria-hidden="true"></i>&nbsp; &nbsp; 以 Facebook
    帳號登入
  </button>
</template>

<script>
/* eslint-disable */
const fbInit = require('@/assets/js/fbInit.js')
export default {
  name: 'FBLoginButton',
  mounted () {
    fbInit()
  },
  methods: {
    fbLogin () {
      const vm = this
      // 檢查登入狀態
      FB.getLoginStatus(function (response) {
        // 登入狀態 - 已登入
        if (response.status === 'connected') {
          // 獲取用戶個人資料
          vm.getProfile()
        } else {
          // 登入狀態 - 未登入
          // 用戶登入(確認授權)
          FB.login(
            function (res) {
              // 獲取用戶個人資料
              vm.getProfile()
            },
            // 授權 - 個人資料&Email
            { scope: 'public_profile,email' }
          )
        }
      })
    },
    getProfile () {
      const vm = this
      FB.api('/me?fields=name,id,email,picture', function (res) {
        // 發送事件到父層
        vm.$emit('FBLoginSuccess', res)
      })
    }
  }
}
</script>
