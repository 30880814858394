<template>
  <!-- <div id="google-signin-button"></div> -->
  <GoogleLogin
    class="btn btn-google w-100 mb-2"
    :params="params"
    :onSuccess="onSuccess"
    :onFailure="onFailure"
  >
    <i class="fa fa-google" aria-hidden="true"></i>&nbsp;
    &nbsp; 以 Google 帳號登入
  </GoogleLogin>
</template>

<script>
import GoogleLogin from 'vue-google-login'
export default {
  name: 'GoogleLoginButton',
  components: { GoogleLogin },
  data () {
    return {
      params: {
        client_id:
          process.env.VUE_APP_GOOGLE_APP_ID
      }
    }
  },
  methods: {
    onSuccess (googleUser) {
      const profile = googleUser.getBasicProfile()
      const userData = {
        id: profile.getId(),
        name: profile.getName(),
        imgURL: profile.getImageUrl(),
        email: profile.getEmail()
      }
      this.$emit('GoogleLoginSuccess', userData)
    },
    onFailure (e) {
      console.log('something went wrong')
      console.log(e)
    }
  }
}
</script>
